import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import EditIcon from "../assets/edit-icon";
import MyMap from "./map";
import Select from "react-select";
import { useGetCitiesQuery } from "../api/generated/graphql";

interface NewSeedModalProps {
  isOpen: boolean;
  onClose: () => void;
  addSeed: (zone: any) => void;
}

export default function NewSeedModal({
  isOpen,
  onClose,
  addSeed,
}: NewSeedModalProps) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const formRef = useRef<HTMLFormElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);

  const [citiesOptions, setCitiesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [isFirstStep, setIsFirstStep] = useState(true);

  const [newBounds, setNewBounds] = useState<any>([]);
  const [newViewport, setNewViewport] = useState<any>();
  const [newLocation, setNewLocation] = useState<any>();

  const { data } = useGetCitiesQuery();

  useEffect(() => {
    if (data?.city) {
      const tempOptions = data.city.map((city) => ({
        label: city.city_name,
        value: city.city_name,
      }));
      setCitiesOptions(tempOptions);
    }
  }, [data]);

  useEffect(() => {
    setModalIsOpen(isOpen);
    Modal.setAppElement("body");
  }, [isOpen]);

  const handleFormSubmit = (formData: any) => {
    if (isFirstStep) {
      const zoneObject = {};
      if (formData.zone_name) {
        Object.assign(zoneObject, { zone_name: formData.zone_name });
      }
      const selectedCityName = formData.city ? formData.city.value : null;
      if (selectedCityName) {
        Object.assign(zoneObject, { city: selectedCityName });
      }
      if (formData.formatted_address) {
        Object.assign(zoneObject, { formatted_address: formData.formatted_address });
      }
      if (formData.bounds) {
        Object.assign(zoneObject, { bounds: JSON.parse(formData.bounds) });
        let geometry = {
          "type": "Polygon",
          "crs": {
            "type": "name",
            "properties": {
              "name": "urn:ogc:def:crs:EPSG::4326"
            }
          },
          "coordinates": [
            [
              ...JSON.parse(formData.bounds).map((bound: any) => [bound.lng, bound.lat]), [JSON.parse(formData.bounds)[0].lng, JSON.parse(formData.bounds)[0].lat]
            ]
          ]
        }
        Object.assign(zoneObject, { geometry: geometry });
      }
      if (formData.location) {
        Object.assign(zoneObject, { location: JSON.parse(formData.location) });
      }
      if (formData.viewport) {
        Object.assign(zoneObject, { viewport: JSON.parse(formData.viewport) });
      }
      addSeed(zoneObject);
      setModalIsOpen(false);
      onClose();
    } else {
      setIsFirstStep(true);
      if (newBounds.length) {
        setValue("bounds", JSON.stringify(newBounds));
      }
      if (newViewport) {
        setValue("viewport", JSON.stringify(newViewport));
      }
      if (newLocation) {
        setValue("location", JSON.stringify(newLocation));
      }
    }
  };

  const calculateViewport = (coords: [number, number][], zoomFactor = 2) => {
    // Finding min and max values for lat and lng
    const lats = coords.map((coord) => coord[0]);
    const lngs = coords.map((coord) => coord[1]);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);

    // Center calculation
    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    // Differences
    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;

    // Zoomed-out differences
    const newLatDiff = latDiff * zoomFactor;
    const newLngDiff = lngDiff * zoomFactor;

    // Calculating new viewport bounds
    const southwest = {
      lat: centerLat - newLatDiff / 2,
      lng: centerLng - newLngDiff / 2,
    };
    const northeast = {
      lat: centerLat + newLatDiff / 2,
      lng: centerLng + newLngDiff / 2,
    };

    return {
        northeast,
        southwest,
    };
  };

  const openMap = () => {
    setIsFirstStep(false);
  };


  const getNewBounds = (bounds: any) => {
    if (bounds.length) {
      const newBounds = bounds.map((bound: any) => ({
        lat: bound[0],
        lng: bound[1],
      }));
      setNewBounds(newBounds);
      const newViewport = calculateViewport(bounds);
      setNewViewport(newViewport);
    }
  };

    // Custom styles for react-select to ensure full width
    const customSelectStyles = {
      control: (provided: any) => ({
        ...provided,
        width: "100%",
      }),
      menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
    };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="blur-overlay"
        style={{
          content: {
            borderRadius: "12px",
            top: "50%",
            left: "50%",
            position: "absolute",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 20rem)",
            height: "h-full",
            minHeight: "50%",
            display: "flex",
            flexDirection: "column",
            outline: "none",
          },
        }}
      >
        <div className=" h-full w-full overflow-hidden rounded-xl px-5">
          <h1 className="text-lg font-bold">
            {isFirstStep ? "New Seed" : "Draw a polygon"}
          </h1>
          <hr className="mt-3" />
          <div className="flex flex-col w-full h-full min-h-96">
            {isFirstStep ? (
              <form
                className="w-full pt-3"
                ref={formRef}
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <div className="flex flex-col space-y-5">
                  {/* Zone name */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="zone_name"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Zone Name
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("zone_name")}
                          id="zone_name"
                          placeholder="Zone Name"
                          className="font-normal text-left text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  {/* City Select */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="city"
                      className="text-base font-medium truncate text-black mb-1"
                    >
                      City
                    </label>
                    <div className="w-full">
                      <Controller
                        control={control}
                        name="city"
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            options={citiesOptions}
                            placeholder="Select City"
                            className="w-full"
                            onChange={(selectedOption) => field.onChange(selectedOption)}
                            value={field.value}
                            styles={customSelectStyles}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {/* Formatted Address */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="formatted_address"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Formatted Address
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("formatted_address")}
                          id="formatted_address"
                          placeholder="Formatted Address"
                          className="font-normal text-left text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Bounds */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="zone_name"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Bounds
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("bounds")}
                          id="bounds"
                          placeholder="Bounds"
                          className="font-normal text-left text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                      <button type="button" onClick={openMap}>
                        <EditIcon />
                      </button>
                    </div>
                  </div>

                  {/* Location */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="location"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Location
                    </label>
                    <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                      <input
                        {...register("location")}
                        id="location"
                        placeholder="Location"
                        className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                        type="text"
                      />
                    </div>
                  </div>


                  {/* View port */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="viewport"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Viewport
                    </label>
                    <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                      <input
                        {...register("viewport")}
                        id="viewport"
                        placeholder="Viewport"
                        className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                        type="text"
                      />
                    </div>
                  </div>

                </div>
              </form>
            ) : (
              <div className="w-full h-[600px] z-50">
                <MyMap
                  position={[41.332237, 19.836116]}
                  zoom={13}
                  enableDraw={true}
                  handleNewPolygon={(bounds: any) => {
                    getNewBounds(bounds);
                  }}
                  handleNewViewport={(viewport: any) => {
                    setNewViewport(viewport);
                  }}
                  handleNewMarker={(coords: any) => {
                    if (coords) {
                      setNewLocation({lat: coords[0], lng: coords[1]});
                    }
                  }}
                />
              </div>
            )}

            {/* Buttons */}
            <div className="flex space-x-5 justify-end h-full py-3 mt-5 flex-none">
              <button
                type="submit"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-900 px-3 py-2 text-white hover:opacity-80 flex items-center justify-center min-h-10`}
                onClick={() => handleSubmit(handleFormSubmit)() }>
                {isFirstStep ? "Add" : "Apply Bounds"}
              </button>
              <button
                type="button"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-200 px-3 py-2 hover:opacity-80`}
                onClick={() => {
                  isFirstStep ? (() => { onClose(); reset(); })() : setIsFirstStep(true);
                }}
                disabled={!isValid}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
