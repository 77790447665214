import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import Select from "react-select";
import { useGetCitiesQuery } from "../api/generated/graphql";

interface NewZoneModalProps {
  isOpen: boolean;
  onClose: () => void;
  addZone: (zone: any) => void;
}

export default function NewZoneModal({
  isOpen,
  onClose,
  addZone,
}: NewZoneModalProps) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const formRef = useRef<HTMLFormElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);

  const [citiesOptions, setCitiesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [isFirstStep, setIsFirstStep] = useState(true);


  const { data } = useGetCitiesQuery();

  useEffect(() => {
    if (data?.city) {
      const tempOptions = data.city.map((city) => ({
        label: city.city_name,
        value: city.city_name,
      }));
      setCitiesOptions(tempOptions);
    }
  }, [data]);

  useEffect(() => {
    setModalIsOpen(isOpen);
    Modal.setAppElement("body");
  }, [isOpen]);

  const handleFormSubmit = (formData: any) => {
      const object = {
        location: formData.location,
        city: formData.city.label,
      }
      addZone(object);
      setModalIsOpen(false);
      onClose();
  };


    // Custom styles for react-select to ensure full width
    const customSelectStyles = {
      control: (provided: any) => ({
        ...provided,
        width: "100%",
      }),
      menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
    };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="blur-overlay"
        style={{
          content: {
            borderRadius: "12px",
            top: "50%",
            left: "50%",
            position: "absolute",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 20rem)",
            height: "h-full",
            display: "flex",
            flexDirection: "column",
            outline: "none",
          },
        }}
      >
        <div className=" h-full w-full overflow-hidden rounded-xl px-5">
          <h1 className="text-lg font-bold">
            New Zone
          </h1>
          <hr className="mt-3" />
          <div className="flex flex-col w-full h-full justify-between min-h-96">
              <form
                className="w-full pt-3"
                ref={formRef}
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <div className="flex flex-col space-y-5">
                  <div className="grid grid-cols-3">
                  </div>
                  {/* Zone name */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="location"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Zone Name
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("location")}
                          id="location"
                          placeholder="Zone Name"
                          className="font-normal text-left text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  {/* City Select */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="city"
                      className="text-base font-medium truncate text-black mb-1"
                    >
                      City
                    </label>
                    <div className="w-full">
                      <Controller
                        control={control}
                        name="city"
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            options={citiesOptions}
                            placeholder="Select City"
                            className="w-full"
                            onChange={(selectedOption) => field.onChange(selectedOption)}
                            value={field.value}
                            styles={customSelectStyles}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>

            {/* Buttons */}
            <div className="flex space-x-5 justify-end h-full py-3 mt-5 flex-none">
              <button
                type="submit"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-900 px-3 py-2 text-white hover:opacity-80 flex items-center justify-center min-h-10`}
                onClick={() => handleSubmit(handleFormSubmit)() }>
                Add
              </button>
              <button
                type="button"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-200 px-3 py-2 hover:opacity-80`}
                onClick={() => {
                  isFirstStep ? (() => { onClose(); reset(); })() : setIsFirstStep(true);
                }}
                disabled={!isValid}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
